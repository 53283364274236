import React, { useEffect } from "react"
import PropTypes from "prop-types"
import { Link, graphql, useStaticQuery } from "gatsby"
import { StaticImage } from "gatsby-plugin-image"
import * as innerHeaderStyles from "./InnerHeader.module.scss"
import { Breadcrumb } from "gatsby-plugin-breadcrumb"

const InnerHeader = ({ pageTitle, pageContext, location }) => {
  pageTitle = pageTitle.toUpperCase()
  const {
    breadcrumb: { crumbs },
  } = pageContext

  let [, , customCrumbLabel] = location.pathname.split("/")
  console.log(customCrumbLabel)

  // for longer page titles, make the font size smaller on smaller screens
  useEffect(() => {
    const isInnerHeaderTitle = document.querySelector(
      ".inner-header-page-title"
    )
    if (isInnerHeaderTitle && isInnerHeaderTitle.innerText.length > 13) {
      if (window.innerWidth < 374) {
        isInnerHeaderTitle.style.fontSize = "2.8rem"
      }
    }
  }, [])

  // any item with class breadcrumb__link will have no hypens and each word will be capitalized
  useEffect(() => {
    const breadcrumbLinks = document.querySelectorAll(".breadcrumb__link")
    breadcrumbLinks?.forEach(link => {
      const linkText = link.innerText.replace(/-/g, " ")
      const linkTextArray = linkText.split(" ")
      const linkTextArrayCapitalized = linkTextArray.map(word => {
        return word.charAt(0).toUpperCase() + word.slice(1)
      })
      const linkTextCapitalized = linkTextArrayCapitalized.join(" ")
      link.innerText = linkTextCapitalized
      //add title attribute to each link
      link.setAttribute("title", linkTextCapitalized)
    })
  }, [])

  return (
    <div className={innerHeaderStyles.wrapper}>
      <div
        className={innerHeaderStyles.heroImageWrapper}
        id="inner-header-1-hero"
      >
        <StaticImage
          src="../../../../assets/images/global/page-partials/inner-header-1/factory-MB.png"
          className={innerHeaderStyles.heroImageMB}
          alt=""
        />
        <StaticImage
          src="../../../../assets/images/global/page-partials/inner-header-1/factory-TB.png"
          className={innerHeaderStyles.heroImageTB}
          alt=""
        />
        <StaticImage
          src="../../../../assets/images/global/page-partials/inner-header-1/factory-DT.png"
          className={innerHeaderStyles.heroImageDT}
          alt=""
        />
      </div>
      <div
        className={`inner-container ${innerHeaderStyles.breadcrumbsAndTitle}`}
      >
        <Breadcrumb
          crumbs={crumbs}
          crumbSeparator=" / "
          crumbLabel={customCrumbLabel}
        />
        <h1 className="inner-header-page-title">
          {pageTitle ? pageTitle : null}
        </h1>
      </div>
    </div>
  )
}

InnerHeader.propTypes = {
  pageTitle: PropTypes.string.isRequired,
}

export default InnerHeader
