import React from "react"
import { Link } from "gatsby"
import brandGridImages from "../../../assets/images/index/family-of-brands"
import * as brandGridStyles from "./brandGridDescription.module.scss"
import "./brandGridDescription.scss"

const FamilyOfBrands = () => {
  return (
    <section className={brandGridStyles.wrapper}>
      {/* the 'inner-container' class just sets global x-axis padding on content */}
      <div className="inner-container">
        <ul className={brandGridStyles.brandsGrid}>
          {/* looping through the imported images array and mapping it out to the grid, note Link component is used for internal NavLinks, but just use traditional anchor tags for external links and buttons */}
          {brandGridImages.map(image => {
            return (
              <>
                <li className={brandGridStyles.brandWrap}>
                  <Link
                    to={image.url}
                    key={image.id}
                    title={image.name}
                    aria-label={image.name}
                    className="brandsLink"
                  >
                    <div className="brandImage">
                      <picture>
                        <source srcSet={image.MB} media="(max-width:1225px)" />
                        <img src={image.DT} alt="" />
                      </picture>
                    </div>
                    <div className={image.cssLogoClass}>
                      <img src={image.logo} alt="" />
                    </div>
                  </Link>
                  <div className={brandGridStyles.description}>
                    <p>{image.description}</p>
                  </div>
                </li>
              </>
            )
          })}
        </ul>
      </div>
    </section>
  )
}

export default FamilyOfBrands
