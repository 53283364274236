import React from "react"
import { Link } from "gatsby"
import InnerHeader from "../../components/global/page-partials/inner-header-1/InnerHeader"
import Layout from "../../components/layout"
import BrandGridDescription from "../../components/index/brand-grid-description/brandGridDescription"

const Brands = ({ pageContext, location }) => {
  const pageTitle = `Family Of Brands`

  return (
    <>
      <Layout>
        <InnerHeader
          pageTitle={pageTitle}
          pageContext={pageContext}
          location={location}
        />
        <BrandGridDescription />
      </Layout>
      {/* description for search, hidden from page */}
      <p
        data-typesense-field="description"
        style={{ display: `none` }}
        aria-hidden="true"
      >
        "Rocky Brands is a leading designer, manufacturer and marketer of
        premium quality footwear and apparel marketed under a portfolio of well
        recognized brand names. Our brands have a rich history of representing
        high quality, comfortable, functional, and durable footwear. Our
        products are organized around six target markets: outdoor, work, duty,
        commercial military, military, and western. As part of our strategy of
        outfitting consumers from head-to-toe, we market complementary branded
        apparel and accessories that we believe leverage the strength and
        positioning of each of our brands."
      </p>
    </>
  )
}

export default Brands

//head element
export const Head = () => (
  <>
    <title data-typesense-field="title">Our Family of Brands</title>
    <meta
      name="description"
      content="Rocky Brands is a leading designer, manufacturer and marketer of premium quality footwear and apparel marketed under a portfolio of well recognized brand names. Our brands have a rich history of representing high quality, comfortable, functional, and durable footwear. Our products are organized around six target markets: outdoor, work, duty, commercial military, military, and western. As part of our strategy of outfitting consumers from head-to-toe, we market complementary branded apparel and accessories that we believe leverage the strength and positioning of each of our brands.​​"
    />
  </>
)
